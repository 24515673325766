"use client";

import { forwardRef, MouseEvent, useEffect, useMemo, useRef, useState } from "react";
import { useWindowSize } from "react-use";

import { useModalReview } from "@Entities/review";
import { useTranslation } from "@Shared/i18n";
import { ElProps } from "@Shared/types";
import { Button, Image, Rating, Text } from "@Shared/ui";
import { twcx } from "@Shared/utils";

type CardPropsClassNames = {
    text?: string;
};

export type CardProps = ElProps<"div"> & {
    classNames?: CardPropsClassNames;
    rating?: number;
    text: string;
    textIsFull?: boolean;
    image?: string;
    name: string;
    caption?: string;
};

export const Card = forwardRef<HTMLDivElement, CardProps>(
    ({ className, classNames, rating, text, textIsFull = false, image, name, caption, ...restProps }, ref) => {
        const refText = useRef<HTMLDivElement>(null);

        const [isTextFull, setIsTextFull] = useState(true);

        const { t } = useTranslation("common");
        const { width } = useWindowSize();
        const { open: modalOpen } = useModalReview();

        const handleClickReadMore = () => {
            modalOpen({
                rating,
                text,
                image,
                name,
                caption,
            });
        };

        useEffect(() => {
            const tl = setTimeout(() => {
                if (!refText.current) {
                    return;
                }

                setIsTextFull(refText.current.offsetHeight >= refText.current.scrollHeight);
            }, 0);

            return () => clearTimeout(tl);
        }, [width]);

        return (
            <div
                ref={ref}
                className={twcx("relative flex w-full flex-col gap-3", "lg:gap-4", className)}
                {...restProps}
            >
                <Text
                    className={twcx(
                        "flex flex-col rounded-xl rounded-bl-sm bg-white p-3 py-4",
                        "lg:rounded-[20px] lg:rounded-bl-[4px] lg:p-6",
                        classNames?.text
                    )}
                    weight={500}
                    color="gray-01"
                >
                    {typeof rating === "number" && (
                        <Rating
                            className={twcx("mb-3 border-b border-b-gray-05 pb-3", "lg:mb-4 lg:pb-4")}
                            value={rating}
                        />
                    )}

                    <div
                        ref={refText}
                        className={twcx({
                            "line-clamp-[12]": !textIsFull,
                            "lg:line-clamp-[11]": !textIsFull,
                        })}
                    >
                        {text}
                    </div>

                    {!textIsFull && !isTextFull && (
                        <Button
                            className={twcx(
                                "mt-2 h-6 gap-0.5 self-start border-b-0 text-p3-d text-black",
                                "lg:mt-3 lg:h-7 lg:text-p3 lg:[&:not(:disabled)]:hover:text-blue-bright"
                            )}
                            classNames={{
                                textIconIcon: "max-lg:text-[16px]",
                            }}
                            variant="underline"
                            icon="angleRightBold"
                            iconSize="sm"
                            onClick={handleClickReadMore}
                        >
                            {t("buttons.readMore")}
                        </Button>
                    )}
                </Text>

                <div className={twcx("flex items-start gap-2", "lg:items-center lg:gap-4")}>
                    <div
                        className={twcx(
                            "relative flex size-11 shrink-0 items-center justify-center overflow-hidden rounded-full",
                            "lg:size-[68px]",
                            {
                                "bg-blue-bright text-white": !image,
                            }
                        )}
                    >
                        {image && (
                            <Image
                                className="size-full object-cover"
                                src={image}
                                alt={name}
                                fill
                                sizes={{ default: "44px", lg: "68px" }}
                            />
                        )}

                        {!image && (
                            <Text variant="h4" weight={600}>
                                {name[0]}
                            </Text>
                        )}
                    </div>

                    <div
                        className={twcx("flex flex-col gap-1", {
                            "self-center": !caption,
                        })}
                    >
                        <Text weight={600} color="white">
                            {name}
                        </Text>

                        {caption && (
                            <Text variant="p2" weight={500} color="gray-04">
                                {caption}
                            </Text>
                        )}
                    </div>
                </div>
            </div>
        );
    }
);

Card.displayName = "Card";
