"use client";

import { FC, useEffect, useRef } from "react";
import { useClickAway, useKeyPressEvent } from "react-use";
import { useParams } from "next/navigation";

import { MODALS } from "@Shared/config";
import { useModalState } from "@Shared/store";
import { ElProps, Nullable } from "@Shared/types";
import { Icon, Image, Rating, Text } from "@Shared/ui";
import { twcx } from "@Shared/utils";

type ModalReviewDataModel = {
    rating?: number;
    text: string;
    image?: string;
    name: string;
    caption?: string;
};

export const useModalReview = () => {
    const { create, open, close, isOpen, getData } = useModalState();

    return {
        data: getData(MODALS.review) as Nullable<ModalReviewDataModel>,
        isOpen: isOpen(MODALS.review),
        create: () => create(MODALS.review),
        open: (data: ModalReviewDataModel) => open(MODALS.review, data),
        close: () => close(MODALS.review),
    };
};

export type ModalReviewProps = ElProps<"div">;

export const ModalReview: FC<ModalReviewProps> = ({ className, ...restProps }) => {
    const refContainer = useRef<HTMLDivElement>(null);

    const params = useParams();
    const { data, isOpen, create, close } = useModalReview();

    useKeyPressEvent("Escape", () => (isOpen ? close() : null));
    useClickAway(refContainer, () => (isOpen ? close() : null));

    useEffect(() => {
        create();
    }, []);

    useEffect(() => {
        isOpen && close();
    }, [params]);

    return (
        <div
            className={twcx(
                "scroll-container-y fixed inset-0 z-10 flex bg-black-total bg-opacity-40 p-5 transition-opacity",
                "lg:p-10",
                {
                    "pointer-events-none opacity-0": !isOpen,
                },
                className
            )}
            {...restProps}
        >
            <div
                ref={refContainer}
                className={twcx(
                    "relative m-auto flex w-full max-w-[400px] flex-col gap-3 overflow-hidden rounded-xl bg-white px-3 py-4 text-gray-01 shadow-[0_12px_30px_0_rgba(0,0,0,0.15)] transition-transform",
                    "lg:max-w-[598px] lg:gap-4 lg:rounded-[20px] lg:p-6",
                    {
                        "scale-95": !isOpen,
                    }
                )}
            >
                <button
                    className={twcx(
                        "absolute right-1 top-2 z-10 flex size-10 items-center justify-center",
                        "lg:right-2 lg:top-2 lg:transition-colors lg:hover:text-blue-bright"
                    )}
                    aria-label="Close modal"
                    onClick={close}
                >
                    <Icon k="close" />
                </button>

                {typeof data?.rating === "number" && (
                    <Rating className={twcx("border-b border-b-gray-05 pb-3 pr-10", "lg:pb-4")} value={data.rating} />
                )}

                <Text weight={500}>{data?.text}</Text>

                <div className={twcx("flex items-start gap-2 pt-1", "lg:items-center lg:gap-4 lg:pt-2")}>
                    <div
                        className={twcx(
                            "relative flex size-11 shrink-0 items-center justify-center overflow-hidden rounded-full",
                            "lg:size-[52px]",
                            {
                                "bg-blue-bright text-white": !data?.image,
                            }
                        )}
                    >
                        {data?.image && (
                            <Image
                                className="size-full object-cover"
                                src={data.image}
                                alt={data?.name}
                                fill
                                sizes={{ default: "44px", lg: "52px" }}
                            />
                        )}

                        {!data?.image && (
                            <Text variant="h4" weight={600}>
                                {data?.name[0]}
                            </Text>
                        )}
                    </div>

                    <div
                        className={twcx("flex flex-col gap-1", {
                            "self-center": !data?.caption,
                        })}
                    >
                        <Text className="lg:text-black" weight={600}>
                            {data?.name}
                        </Text>

                        {data?.caption && (
                            <Text variant="p2" weight={500} color="gray-03">
                                {data.caption}
                            </Text>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
