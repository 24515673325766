import(/* webpackMode: "eager" */ "/home/app/frontend/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["toast"] */ "/home/app/frontend/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/pages/home/ui/sections/about/About.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["List"] */ "/home/app/frontend/src/pages/home/ui/sections/advantages/List.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ImageChain"] */ "/home/app/frontend/src/pages/home/ui/sections/group-goal-reviews/goal/ImageChain.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["List"] */ "/home/app/frontend/src/pages/home/ui/sections/group-goal-reviews/reviews/List.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ButtonContactUs"] */ "/home/app/frontend/src/pages/home/ui/sections/intro/ButtonContactUs.tsx");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/pages/home/ui/sections/intro/images/awwwards.svg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/pages/home/ui/sections/intro/images/bg-d.webp");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/pages/home/ui/sections/intro/images/bg.webp");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/pages/home/ui/sections/intro/images/glass-d.webp");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/pages/home/ui/sections/intro/images/glass.webp");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/pages/home/ui/sections/Movie.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["List"] */ "/home/app/frontend/src/pages/home/ui/sections/works/List.tsx");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/i18n/client.ts");
;
import(/* webpackMode: "eager", webpackExports: ["YandexMetrika"] */ "/home/app/frontend/src/shared/ui/analytics/YandexMetrika.tsx");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/animation/FadeIn.tsx");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/animation/Number.tsx");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/animation/TextFilling.tsx");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/button/Button.tsx");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/button/ButtonSubmit.tsx");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/icon/images/angle-down-sm.svg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/icon/images/angle-right-bold.svg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/icon/images/angle-right.svg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/icon/images/angle-top-sm.svg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/icon/images/arrow-left.svg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/icon/images/arrow-right.svg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/icon/images/arrow-top-right.svg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/icon/images/behance.svg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/icon/images/close-circle.svg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/icon/images/close.svg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/icon/images/dribbble.svg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/icon/images/globe.svg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/icon/images/image.svg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/icon/images/instagram.svg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/icon/images/lightning.svg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/icon/images/linkedin.svg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/icon/images/menu-close.svg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/icon/images/menu.svg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/icon/images/rhombus-rounded.svg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/icon/images/star.svg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/icon/images/telegram.svg");
;
import(/* webpackMode: "eager", webpackExports: ["ImageWithLoader"] */ "/home/app/frontend/src/shared/ui/image/ImageWithLoader.tsx");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/input/Input.tsx");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/slider/Slider.tsx");
